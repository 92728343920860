@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-Regular.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-Regular.ttf') format('truetype');;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-Italic.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-Light.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-LightItalic.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-ExtraLight.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-ExtraLightItalic.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-SemiBold.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-SemiBoldItalic.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-Bold.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-BoldItalic.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-Black.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src:url('/assets/font/SourceSansPro-BlackItalic.ttf') format('truetype'),
  url('/assets/font/SourceSansPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
