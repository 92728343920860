//TODO : all colors have to be changed to the picked PRO color

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "node_modules/@angular/material" as mat;

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$qr-butler-fe-primary: mat.define-palette(mat.$indigo-palette);
$qr-butler-fe-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$qr-butler-fe-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$qr-butler-fe-theme: mat.define-light-theme((
  color: (
    primary: $qr-butler-fe-primary,
    accent: $qr-butler-fe-accent,
    warn: $qr-butler-fe-warn,
  )
));

$qr-butler-fe-dark-theme: mat.define-dark-theme((
  color: (
    primary: $qr-butler-fe-primary,
    accent: $qr-butler-fe-accent,
    warn: $qr-butler-fe-warn,
  )
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($qr-butler-fe-theme);

@import 'src/app/shared/fonts.scss';
@import 'src/app/shared/font-weights.scss';
@import '~@adyen/adyen-web/dist/adyen.css';

* {
  font-family: 'Source Sans Pro', serif;
  font-weight: $weight-regular;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    opacity: 0 !important; /*click effect color change*/
    background-color: var(--pro-color) !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: var(--pro-color) !important; /*inner circle color change*/
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--pro-color) !important; /*outer ring color change*/
  }

}

html, body {
  height: 100%;
}

body {
  margin: 0;
  overflow: hidden;
}

.dark-theme {
  @include mat.all-component-themes($qr-butler-fe-dark-theme);

  .mat-drawer-inner-container {
    background-color: #242624;
  }

  button:disabled {
    opacity: .38;
    color: #000000 !important;
  }

  mat-toolbar {
    background: #121212 !important;
  }

  mat-card {
    background: #211F25 !important;
  }

  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: #00000099;
  }

  .toast-colour {
    background-color: #211F25;
  }

  .toast-cross {
    filter: invert(100%) sepia(8%) saturate(0%) hue-rotate(247deg) brightness(107%) contrast(106%);
  }

  h1 {
    font-size: 96px;
    font-weight: $weight-light;
  }

  h2 {
    font-size: 60px;
    font-weight: $weight-light;
  }

  h3 {
    font-size: 32px;
    font-weight: $weight-semi-bold;
  }

  h4 {
    font-size: 26px;
    font-weight: $weight-regular;
  }

  h5 {
    font-size: 20px;
    font-weight: $weight-regular;
  }

  h6 {
    font-size: 18px;
    font-weight: $weight-semi-bold;
    margin: 8px 0;
  }

  .subtitle-1 {
    font-size: 18px;
    font-weight: $weight-semi-bold;
  }

  .subtitle-2 {
    font-size: 16px;
    font-weight: $weight-regular;
  }

  .body-1 {
    font-size: 16px;
    font-weight: $weight-regular;
  }

  .body-2 {
    font-size: 14px;
    font-weight: $weight-regular;
  }

  .floating-button {
    //text-style
    font-size: 16px;
    font-weight: $weight-semi-bold;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #000000;

    //buttons style
    width: 100%;
    height: 48px;
    text-align: center;
    vertical-align: center;
    background-color: var(--pro-color);
    border-radius: 24px !important;
  }

  .caption {
    font-size: 12px;
    font-weight: $weight-regular;
  }

  .overline {
    font-size: 12px;
    font-weight: $weight-regular;
    text-transform: uppercase;
  }

  .normal-button {
    height: 36px;
    border-radius: 4px !important;
    background-color: var(--pro-color);
    padding: 0 16px 0 16px;
    text-align: center;
    vertical-align: center;
    color: #ffffff;

    .mat-button-focus-overlay {
      background-color: #ffffff;
    }

    &:disabled {
      background-color: #868686;
    }
  }

  .text-button {
    height: 36px;
    border-radius: 4px !important;
    background-color: transparent;
    padding: 0 16px 0 16px;
    text-align: center;
    vertical-align: center;
    color: var(--pro-color);

    .mat-button-focus-overlay {
      background-color: var(--pro-color);
    }

    &:disabled {
      color: black;
    }
  }

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: var(--pro-color);
  }

  .payment-button {
    //text-style
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: white;

    //buttons style
    width: calc(100% - 20px);
    height: 48px;
    text-align: center;
    vertical-align: center;
    background-color: #FFFFFF1F;
    border-radius: 24px !important;
    margin: 12px 12px 0 10px;
    position: absolute;
    bottom: 15px;
  }

  .timer {
    margin-top: 40px;
    color: #FFFFFF8F;
  }

  :root {
    --dark-primary: #5CF0CC;
    --dark-primary-dark: #05E8B1;
    --dark-secondary: #06f;
    --dark-highlight: #CDFAEF;
    --dark-hover: #394962;
    --dark-background: #211F25;
    --dark-border: #374661;
  }

  .adyen-checkout__payment-method {
    /* Payment method container */
    background-color: #211F25!important;
    border-color: #FFFFFF14;
  }

  .adyen-checkout__payment-method--selected {
    /* Payment method that has been selected */
    background-color: #211F25!important;

  }

  .adyen-checkout__payment-method__radio--selected {
    /* Selected radio button in payment method header */
    background-color: var(--pro-color);
  }

  .adyen-checkout__payment-method__radio--selected:after {
    background-color: #303030;
  }

  .adyen-checkout__payment-method__header__title {
    color: white;
  }

  .adyen-checkout__button {
    background: var(--pro-color);
    border-radius: 24px;
    color: black;
    text-transform: uppercase;
  }

  .adyen-checkout__button__icon {
    filter: invert(1);
  }

  .adyen-checkout__input {
    background: #ffffff;
    caret-color: var(--pro-color);
  }

  .adyen-checkout__label__text {
    color: white;
  }

  .input-field {
    color: rgb(0, 17, 44);
    font-size: 16px !important;
    font-weight: 400;
    caret-color: var(--pro-color);
  }

  .adyen-checkout__dropdown__list {
    color: black;
  }

  mat-form-field {
    font-size: 16px;
  }
}

.light-theme {
  @include mat.all-component-themes($qr-butler-fe-theme);

  .toast-colour {
    background-color: white;
  }

  .toast-cross {
    filter: invert(0%) sepia(43%) saturate(13%) hue-rotate(248deg) brightness(96%) contrast(103%);
  }

  button:disabled {
    opacity: .38;
    color: #ffffff !important;
  }

  mat-toolbar {
    background: #ffffff !important;
  }

  h1 {
    font-size: 96px;
    font-weight: $weight-light;
  }

  h2 {
    font-size: 60px;
    font-weight: $weight-light;
  }

  h3 {
    font-size: 32px;
    font-weight: $weight-semi-bold;
  }

  h4 {
    font-size: 26px;
    font-weight: $weight-regular;
  }

  h5 {
    font-size: 20px;
    font-weight: $weight-regular;
  }

  h6 {
    margin: 8px 0;
    font-size: 18px;
    font-weight: $weight-semi-bold;
  }

  .subtitle-1 {
    font-size: 18px;
    font-weight: $weight-semi-bold;
  }

  .subtitle-2 {
    font-size: 16px;
    font-weight: $weight-regular;
  }

  .body-1 {
    font-size: 16px;
    font-weight: $weight-regular;
  }

  .body-2 {
    font-size: 14px;
    font-weight: $weight-regular;
  }

  .floating-button {
    //text-style
    font-size: 16px;
    font-weight: $weight-semi-bold;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #ffffff;

    //buttons style
    width: 100%;
    height: 48px;
    text-align: center;
    vertical-align: center;
    background-color: var(--pro-color);
    border-radius: 24px !important;
  }

  .caption {
    font-size: 12px;
    font-weight: $weight-regular;
  }

  .overline {
    font-size: 12px;
    font-weight: $weight-regular;
    text-transform: uppercase;
  }

  .normal-button {
    height: 36px;
    border-radius: 4px !important;
    background-color: var(--pro-color);
    padding: 0 16px 0 16px;
    text-align: center;
    vertical-align: center;
    color: #ffffff;

    .mat-button-focus-overlay {
      background-color: #ffffff;
    }

    &:disabled {
      background-color: #e1e1e1;
    }
  }

  .text-button {
    height: 36px;
    border-radius: 4px !important;
    background-color: transparent;
    padding: 0 16px 0 16px;
    text-align: center;
    vertical-align: center;
    color: var(--pro-color);

    .mat-button-focus-overlay {
      background-color: var(--pro-color);
    }

    &:disabled {
      color: black;
    }
  }

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: var(--pro-color);
  }

  .payment-button {
    //text-style
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: black;

    //buttons style
    width: calc(100% - 20px);
    height: 48px;
    text-align: center;
    vertical-align: center;
    background-color: #0000001F;
    border-radius: 24px !important;
    margin: 12px 12px 0 10px;
    position: absolute;
    bottom: 15px;
  }

  .timer {
    margin-top: 40px;
    color: #0000008F;
  }

  :root {
    --dark-primary: #5CF0CC;
    --dark-primary-dark: #05E8B1;
    --dark-secondary: #06f;
    --dark-highlight: #CDFAEF;
    --dark-hover: #394962;
    --dark-background: #211F25;
    --dark-border: #374661;
  }

  .adyen-checkout__payment-method {
    /* Payment method container */
    background-color: #0000000A;
    border-color: #00000014;
  }

  .adyen-checkout__payment-method--selected {
    /* Payment method that has been selected */
    background-color: #0000000A;
  }

  .adyen-checkout__payment-method__radio--selected {
    /* Selected radio button in payment method header */
    background-color: var(--pro-color);
  }

  .adyen-checkout__payment-method__radio--selected:after {
    background-color: #e8e8e8;
  }

  .adyen-checkout__payment-method__header__title {
    color: black;
  }

  .adyen-checkout__button {
    background: var(--pro-color);
    border-radius: 24px;
    color: white;
    text-transform: uppercase;
  }

  .adyen-checkout__input {
    background: #ffffff;
    caret-color: var(--pro-color);
  }

  .adyen-checkout__label__text {
    color: black;
  }

  .adyen-checkout__dropdown__list {
    color: black;
  }

  mat-form-field {
    font-size: 16px;
  }
}


h1 {
  font-size: 96px;
  font-weight: $weight-light;
}

h2 {
  font-size: 60px;
  font-weight: $weight-light;
}

h3 {
  font-size: 32px;
  font-weight: $weight-semi-bold;
}

h4 {
  font-size: 26px;
  font-weight: $weight-regular;
}

h5 {
  font-size: 20px;
  font-weight: $weight-regular;
}

h6 {
  margin: 8px 0;
  font-size: 18px;
  font-weight: $weight-semi-bold;
}

.subtitle-1 {
  font-size: 18px;
  font-weight: $weight-semi-bold;
}

.subtitle-2 {
  font-size: 16px;
  font-weight: $weight-regular;
}

.body-1 {
  font-size: 16px;
  font-weight: $weight-regular;
}

.body-2 {
  font-size: 14px;
  font-weight: $weight-regular;
}

.floating-button {
  //text-style
  font-size: 16px;
  font-weight: $weight-semi-bold;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  //buttons style
  width: 100%;
  height: 48px;
  text-align: center;
  vertical-align: center;
  background-color: var(--pro-color) !important;
  border-radius: 24px !important;
}

.caption {
  font-size: 12px;
  font-weight: $weight-regular;
}

.overline {
  font-size: 12px;
  font-weight: $weight-regular;
  text-transform: uppercase;
}

.normal-button {
  height: 36px;
  border-radius: 4px !important;
  background-color: var(--pro-color);
  padding: 0 16px 0 16px;
  text-align: center;
  vertical-align: center;
  color: #ffffff;

  .mat-button-focus-overlay {
    background-color: #ffffff;
  }

  &:disabled {
    background-color: #e1e1e1;
  }
}

.text-button {
  height: 36px;
  border-radius: 4px !important;
  background-color: transparent;
  padding: 0 16px 0 16px;
  text-align: center;
  vertical-align: center;
  color: var(--pro-color)!important;
  text-transform: uppercase;

  .mat-button-focus-overlay {
    background-color: var(--pro-color);
  }

  &:disabled {
    color: black;
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: var(--pro-color);
}

.mat-focused .mat-form-field-label {
  color: var(--pro-color) !important;
}

.mat-focused .mat-form-field-ripple {
  background-color: var(--pro-color) !important;
}

::ng-deep input.mat-input-element {
  color: var(--pro-color) !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--pro-color) !important;
}

.mat-input-element {
  caret-color: var(--pro-color) !important;
}

.mat-drawer-content {
  display: inline-block;
  overflow: auto;
}

.custom-sheet-corner-dark {
  border-radius: 10px 10px 0 0;
  max-width: 400px;
  background-color: #211F25;
}

.custom-sheet-corner-light {
  border-radius: 10px 10px 0 0;
  max-width: 400px;
}

mat-toolbar {
  font-size: 18px !important;
  font-family: 'Source Sans Pro', serif !important;
  position: relative;
  z-index: 1;
}

/* The popup bubble styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: white;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  top: 35px;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  width: 250px;
}

.dark-dialog {
  mat-dialog-container {
    border-radius: 10px;
    background-color: #242624;
  }
}

.light-dialog {
  mat-dialog-container {
    border-radius: 10px;
  }
}

.mat-placeholder-required.mat-form-field-required-marker {
  color: inherit !important;
}

.adyen-checkout__spinner {
  border: 3px solid var(--pro-color);
}

.custom-dialog-container-dark .mat-dialog-container {
  padding: 12px;
  background-color: #242624;
  color: white;
}

.custom-dialog-container-light .mat-dialog-container {
  padding: 12px;
}

.adyen-checkout__threeds2__challenge--02 {
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 100%;

  iframe {
    width: 100% !important;
  }
}

.cdk-overlay-dark-backdrop {
  background: #00000099;
}

app-cookie-page {
  background-color: white;
}
